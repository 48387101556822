import React, {Fragment, useEffect, useState} from 'react';
import s from './Offers.module.scss';
import PageHeader from "../../components/PageHeader";
import SearchInput from "../../components/UI/Inputs/SearchInput";
import Table from "../../components/UI/Table";
import {ITableDataHeader} from "../../components/UI/Table/Table";
import TableColumn from "../../components/UI/Table/components/TableColumn/TableColumn";
import TableRow from "../../components/UI/Table/components/TableRow";
import {ENDPOINTS} from "../../api/endpoints";
import Loader from "../../components/UI/Loader";
import {useAppSelector} from "../../store";
import {Links} from "../../api/interface/Links";
import copyTextToClipboard from "../../utils/copyTextToClipboard";
import {Helmet} from "react-helmet";

export default function OffersPage() {

    const tableGrid = ['60px', '1fr', '200px', '200px'];
    const headerData: ITableDataHeader[] = [
        {title: 'ID'},
        {title: 'Название'},
        {title: 'SmartBot'},
        {title: 'Ссылка'},
    ];

    const {rights, id} = useAppSelector((state) => state.profile)
    const [offers, setOffers] = useState<Links[]>([])
    const [isLoading, setIsLoading] = useState<boolean>(true)

    const [name, setName] = useState<string>('');

    const currentItems = offers.filter(o => {
        return o.offer.title ? o.offer.title.toLowerCase().indexOf(name.toLowerCase()) !== -1 : true
    });

    useEffect(() => {
        ENDPOINTS.LINKS.GET().then((res) => {
            setOffers(res)
            setIsLoading(false)
        })
    }, []);

    return (
        <div className={s.container}>
            <Helmet>
                <title>Офферы | Shark Cpa</title>
            </Helmet>
            <PageHeader title={"Офферы"} text={rights === "ADMIN" ? "Добавить": undefined} url={rights === "ADMIN" ? "/offers/create": undefined} create={() => {
            }} childrenPosition={"end"}>
                <SearchInput onChange={(e) => setName(e.currentTarget.value)} value={name}/>
            </PageHeader>

            <div className={s.container__products}>
                <Table dataHeaders={headerData} className={s.container__products_table} gridWidthSize={tableGrid}>
                    {headerData && <div className={s.underlined}/>}
                    {currentItems.map((item, index) => (
                        <Fragment key={index}>
                            <TableRow gridWidthSize={tableGrid} hover={true}>
                                <TableColumn>
                                    <div>{item.id}</div>
                                </TableColumn>
                                <TableColumn>
                                    <div className={s.title}><img src={item.offer.logo} alt={"card"}/>
                                        <span>{item.offer.title}</span></div>
                                </TableColumn>
                                <TableColumn>
                                    <button className={s.hoverItem} onClick={(e) => {
                                        e.preventDefault()
                                        copyTextToClipboard(`https://shark-cpa.ru/link/${item.custom_link}?c=${id}&o=${item.offer.id}&v=%id_польз%` || "")
                                    }}>Скопировать
                                    </button>
                                </TableColumn>
                                <TableColumn>
                                    <button className={s.hoverItem} onClick={(e) => {
                                        e.preventDefault()
                                        copyTextToClipboard('https://shark-cpa.ru/link/' + item.custom_link || "")
                                    }}>Скопировать
                                    </button>
                                </TableColumn>
                            </TableRow>
                        </Fragment>
                    ))}
                </Table>
                {isLoading && <Loader/>}
            </div>
        </div>
    )
}
